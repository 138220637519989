<template>
  <div
    :id="(commande.type_cmd == 6) ? 'partie_installation' : 'partie_materiel'"
    class="commande scrollme"
    :class="disableInteraction ? 'no-interaction-ligne' : ''"
  >
    <div
      class="d-flex"
    >
      <p
        class="text-h4"
      >
        {{ $t('contenu.title', {statut: commande.is_configInternaute || commande.is_petite_source_internaute ? $t('commande.statut.config') : (!commande.is_configInternaute && !commande.is_petite_source_internaute && commande.statut == 'devis' ? $t('commande.statut.devis') : $t('commande.statut.commande')) }) }}
        {{ (commande.type_cmd == 6) ? " " + $t('contenu.installation') : (commande.type_cmd == 8 ? " " + $t('contenu.petite-source') : " " + $t('contenu.materiel')) + " #"+commande.n }}
        <span class="d-block text-h5">{{ (commande.type_cmd == 7 || commande.type_cmd == 1) ? $t('contenu.hors-installation') : '' }}</span>
      </p>
      <v-btn
        small
        icon
        fab
        class="ml-auto"
      >
        <v-icon
          v-if="show_lignes"
          @click="show_lignes = !show_lignes"
        >
          mdi-arrow-down-drop-circle
        </v-icon>
        <v-icon
          v-if="!show_lignes"
          @click="show_lignes = !show_lignes"
        >
          mdi-arrow-up-drop-circle
        </v-icon>
      </v-btn>
    </div>
    <p class="muted--text">
      {{ commande.is_configInternaute || commande.is_petite_source_internaute ? '' : (commande.type_cmd == 6 ? $t('devis_install_desc') : $t('devis_materiel_desc')) }}
    </p>
    <div v-if="show_lignes">
      <div
        class="elevation rounded-sm"
      >
        <ArticleCard
          v-for="(article, index) in commande.lignes"
          :key="index"
          :article="article"
          :mode-affichage-prix="modeAffichagePrix"
          :hide-savoir-plus="commande.statut == 'facture' ? true : false"
          class="rounded-0"
          @click="disableInteraction ? '' : showLigneDetail(index)"
        />
        <DialogCarousel
          v-model="carouselLigne"
          :items="commande.lignes"
        >
          <template v-slot:default="{ item }">
            <v-card class="contenu-piscinelle overflow-y-hidden">
              <v-card-title class="d-block pa-3 dialog-card-color white--text align-start">
                <v-btn
                  class="float-right"
                  dark
                  icon
                  @click="carouselLigne = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <p class="ma-0">
                  {{ item.desig }}
                </p>
                <small
                  v-if="item.qte > 1"
                  class="mr-2"
                >{{ $t('quantite') + ' : ' + item.qte }}</small>
                <small>{{ item.li_mtt_client_ttc != 0 ? $t('prix_ttc') + ' : ' + (commande.type_cmd == 8 ? $n(vpm(item.li_mtt_client_ttc,180), 'currency') + ' ' + $t('par-mois') + '*' : $n(item.li_mtt_client_ttc, 'currency')) : (remarque_ligne_client(item.remarque) ? $t('client') : $t('inclus')) }}</small>
                <a
                  v-if="isAdmin() && item.id_contenu"
                  class="white--text text-decoration-underline text-body-2 ml-5"
                  target="_blank"
                  :href="piscinelleUrl + 'admin/contenu-edition/'+item.id_contenu+(commande.is_petite_source ? '?petite-source=true' : '')"
                >Modifier le descriptif</a>
                <v-spacer />
              </v-card-title>
              <v-card-text
                class="pa-0 pb-15 fill-height overflow-y-hidden"
              >
                <iframe
                  v-if="item.contenu"
                  class="border-none"
                  width="100%"
                  height="100%"
                  :src="piscinelleUrl + ($i18n.locale == 'fr' ? '' : $i18n.locale+'/') + 'utils/contenu-iframe?co='+item.id_contenu"
                />
                <div v-else>
                  <p class="ma-3">
                    {{ $t('contenu.inexistant') }}
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </DialogCarousel>
        <v-alert
          v-for="(remise, index) in commande.remises"
          :key="index"
          color="green"
          dense
          dark
          class="pa-2 mb-0 rounded-0"
        >
          <div class="d-flex justify-space-between">
            <span class="d-flex align-center">
              <v-icon
                left
              >mdi-sale</v-icon>
              {{ remise.desig }}
            </span>
            <span>{{ $n(remise.li_mtt_client_ttc, 'currency') }}</span>
          </div>
        </v-alert>
        <v-card
          v-if="commande.fdp_mtt_ttc && commande.type_cmd != 8"
          flat
          class="d-flex align-center justify-space-between pa-2 mb-0"
        >
          <div class="d-flex flex-column">
            <span class="font-weight-medium">{{ $t('frais_de_port') }}</span>
            <span class="text-caption">{{ commande.expedition_mode | capitalize({ onlyFirstLetter: true }) }}</span>
          </div>
          <div class="ttc d-flex flex-column align-end text-right">
            <span
              v-if="commande.statut == 'devis' && (modeAffichagePrix == 1 || modeAffichagePrix == 3)"
              class="text-h6"
            >{{ $n(vpm(commande.fdp_mtt_ttc,180), 'currency') }} {{ $t('par-mois') }}*</span>
            <span
              v-if=" modeAffichagePrix != 3"
              class="ttc-comptant"
              :class="modeAffichagePrix == 1 ? 'muted--text text-body-1' : 'text-h6'"
            >{{ $n(commande.fdp_mtt_ttc, 'currency') }} {{ $t('au-comptant') }}</span>
          </div>
        </v-card>
        <v-divider v-if="commande.fdp_mtt_ttc && commande.type_cmd != 8" />
        <v-card
          class="contenu-piscinelle font-weight-medium pa-2"
          flat
        >
          <div class="d-flex justify-center align-center justify-space-between">
            <div>
              <p class="text-h6 font-weight-medium mb-2">
                {{ commande.type_cmd == '6' ? $t('total_ttc_install') : (commande.type_cmd == 8 ? $t('total_ttc_ps') : $t('total_ttc_materiel')) }}
              </p>
              <a
                v-if="commande.is_configInternaute || commande.is_petite_source_internaute"
                class="muted--text"
                :href="modifierConfigUrl"
              >
                <v-icon
                  small
                  color="muted"
                  class="vertical-baseline"
                >mdi-cog</v-icon>
                {{ $t('modifier-config') }}
              </a>
            </div>
            <div class="ttc d-flex flex-column align-end text-right">
              <span
                v-if="modeAffichagePrix == 3 || (commande.statut == 'devis' && modeAffichagePrix == 1)"
                class="text-h6"
              >{{ $n(vpm(commande.ce_mtt_client_ttc,180), 'currency') }} {{ $t('par-mois') }}*</span>
              <span
                v-if="modeAffichagePrix != 3"
                class="ttc-comptant"
                :class="modeAffichagePrix == 1 ? 'muted--text text-body-1' : 'text-h6'"
              >{{ $n(commande.ce_mtt_client_ttc, 'currency') }} {{ $t('au-comptant') }}</span>
              <span class="text-body-1 muted--text">{{ (commande.type_cmd == 7 || commande.type_cmd == 1) && !commande.offre_liee ? " " + $t('contenu.hors-installation') : "" }}</span>
              <span
                v-if="commande.taux_tva > 0 && commande.type_cmd != 8"
                class="text-body-1 muted--text"
              >{{ $t('total-ht', {montant: $n(commande.ce_mtt_client_ht)}) }}</span>
              <span
                v-if="commande.taux_tva > 0 && commande.type_cmd != 8"
                class="text-body-1 muted--text"
              >{{ $t('taux-tva', {taux: commande.taux_tva}) }}</span>
            </div>
          </div>
        </v-card>
      </div>
      <v-divider />

      <template v-if="commande.versions">
        <div class="muted--text text-right">
          <a
            class="muted--text"
            @click="commande_versions = true"
          >
            <v-icon
              color="muted"
              class="vertical-top"
            >
              mdi-update
            </v-icon> {{ $t('versions') }}
          </a>
          <v-dialog
            v-model="commande_versions"
            :persistent="$vuetify.breakpoint.smAndDown"
            scrollable
            max-width="750px"
            content-class="force-100-height hide-scroll-while-open"
            transition="dialog-bottom-transition"
            :fullscreen="$vuetify.breakpoint.smAndDown"
          >
            <v-card>
              <v-card-title
                class="dialog-card-color pa-3 white--text flex-nowrap align-start"
              >
                {{ $t('detail-versions') }}
                <v-spacer />
                <v-btn
                  icon
                  dark
                  @click="commande_versions = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text
                class="pa-5"
                style="height: 100%;"
              >
                <v-select
                  v-model="versions[0]"
                  :items="versions"
                  item-text="label"
                  item-value="value"
                  :label="$t('choix-version')"
                  required
                  return-object
                  @change="getVersion($event)"
                />
                <div
                  v-for="(version,index) in commande.versions"
                  :id="version.date"
                  :key="version.id"
                  class="div-versions"
                >
                  <p class="text-subtitle-1 black--text mb-1">
                    {{ $t('version', {actuelle: (index == 0 ? $t('actuelle') : '') ,date: $d(new Date(version.date.replace(/-/g, '/')), 'long') }) }}
                  </p>
                  <v-simple-table
                    ref="table-versions"
                    class="table-versions mb-2"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left black--text text-subtitle-2 py-1">
                            {{ $t('designation') }}
                          </th>
                          <th class="text-right black--text text-subtitle-2 py-1">
                            {{ $t('prix_ttc') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(ligne, ligneIndex) in version.version_infos.lignes"
                          :key="ligneIndex"
                        >
                          <td class="py-1">
                            {{ ligne.desig }}
                          </td>
                          <td class="text-right py-1">
                            {{ $n(ligne.li_mtt_client_ttc, 'currency') }}
                          </td>
                        </tr>
                        <tr>
                          <td class="py-1">
                            {{ $t('total_ttc') }}
                          </td>
                          <td class="text-right py-1">
                            {{ $n(version.version_infos.total, 'currency') }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <div
        v-if="(commande.type_cmd == 1 || commande.type_cmd == 6) && (commande.remarque_client || commande.remarque_liv)"
        class="pa-2 info white--text mt-10 mb-7"
      >
        <v-card
          flat
          class="remarques info"
        >
          <div v-if="commande.remarque_client">
            <p class="pa-0 white--text text-h6 mb-0">
              {{ $t('remarque-client') }}
            </p>
            <p class="pa-0 mb-3 white--text">
              {{ commande.remarque_client }}
            </p>
          </div>
          <div v-if="commande.remarque_liv">
            <p class="pa-0 white--text text-h6 mb-0">
              {{ $t('remarque-livraison') }}
            </p>
            <p class="pa-0 mb-3 white--text">
              {{ commande.remarque_liv }}
            </p>
          </div>
        </v-card>
      </div>
      <div
        :id="ancre ? 'acompte' : ''"
        class="pa-2"
      >
        <p class="text-h6">
          {{ $t('modalites') + " " + (commande.type_cmd == '6' ? $t('contenu.installation') : (commande.type_cmd == 8 ? $t('contenu.petite-source') : $t('contenu.materiel'))) }}
        </p>
        <v-card
          flat
          class="mx-auto mb-5 modalites"
        >
          <v-list-item
            v-if="!commande.is_configInternaute && !commande.is_petite_source_internaute && commande.type_cmd != 8"
            class="pa-0"
          >
            <v-list-item-content
              v-if="commande.type_cmd != 8"
              class="py-0"
            >
              <p
                class="mb-0 p-modalites"
                :inner-html.prop="commande.paiement_modalites"
              />
            </v-list-item-content>
          </v-list-item>
          <v-card-actions class="pa-0 flex-column align-start">
            <v-dialog
              v-if="!commande.is_configInternaute && !commande.is_petite_source_internaute"
              v-model="dialogPayerAcompte"
              scrollable
              max-width="700"
              :fullscreen="$vuetify.breakpoint.smAndDown"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-if="!commande.is_acompte_ok && (commande.statut == 'commande' || commande.statut == 'facture' || (commande.statut=='devis' && !commande.is_configInternaute && !commande.is_petite_source_internaute && commande.documents_joints.length > 0))"
                  class="black--text"
                  href="javascript:void(0)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('payer-acompte') }}
                </a>
              </template>
              <v-card
                class="card-payer-acompte contenu-piscinelle"
              >
                <v-card-title class="dialog-card-color pa-3 white--text flex-nowrap align-start">
                  {{ $t('payer-acompte') }}
                  <v-spacer />
                  <v-btn
                    icon
                    dark
                    @click="dialogPayerAcompte = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div :inner-html.prop="$options.filters.replaceInterrogationByAnd($options.filters.putEmailEndOfPiscinelleLinks($options.filters.allLinksToBlank(commande.paiement_modalite_acompte), user.email))" />
                </v-card-text>
              </v-card>
            </v-dialog>
            <Facilites
              v-if="!commande.offre_liee && !install"
              :commande="commande"
            />
          </v-card-actions>
        </v-card>
      </div>
      <div
        v-if="commande.statut != 'devis' || (commande.acomptes && commande.acomptes != 0 && commande.acomptes.length > 0)"
        class="pa-2"
      >
        <p class="text-h6">
          {{ $t('acomptes-recus') }}
        </p>
        <v-card
          flat
          class="contenu-piscinelle"
        >
          <div
            v-if="commande.acomptes && commande.acomptes != 0 && commande.acomptes.length > 0"
          >
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(acompte, index) in commande.acomptes"
                    :key="index"
                  >
                    <td v-if="acompte.date">
                      {{ $d(new Date(acompte.date.replace(/-/g, "/")), 'short') }}
                    </td>
                    <td>{{ commande.paiement_moyens[acompte.moyen] }}</td>
                    <td class="text-right">
                      {{ $n(acompte.mtt, 'currency') }}
                    </td>
                    <td>{{ acompte.rmq }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="mt-5">
              {{ $t("reste_a_payer") + " : " }} <span class="colorPrimary">{{ $n(commande.reste_a_payer, 'currency') }}</span>
            </p>
          </div>
          <div
            v-else
          >
            <p>
              {{ $t('pas-d-acompte') }}
            </p>
          </div>
        </v-card>
      </div>
      <div
        v-if="commande.type_cmd == 1 && commande.documents_joints.length > 0 "
        class="pa-2"
      >
        <p class="text-h6 mb-0">
          {{ $t('documents_joints') }}
        </p>
        <div class="d-flex align-center flex-wrap">
          <v-card
            v-for="(document, index) in commande.documents_joints"
            :key="index"
            class="mr-4 mt-4"
            flat
          >
            <v-card-text
              class="pa-0"
            >
              <a
                class="text-decoration-none position-relative pa-1 pb-5 document d-flex flex-column justify-center align-center overflow-hidden"
                :href="document.fichier"
                target="_blank"
              >
                <v-icon
                  color="primary"
                  x-large
                >
                  mdi-file-pdf-box
                </v-icon>
                <span class="colorPrimary text-center overflow-hidden">{{ document.titre }}</span>
                <span
                  :class="user.is_petite_source ? 'background-ps-primary' : 'background-primary'"
                  class="position-absolute document-date d-block w-100 text-center white--text"
                >{{ document.date }}</span>
              </a>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div
        v-if="commande.type_cmd == '6' && !commande.is_configInternaute && !commande.is_petite_source_internaute"
        class="societe-install font-weight-regular pa-2 muted--text"
      >
        <p class="mb-2">
          {{ $t('societe_install') }} <span class="font-weight-bold">{{ commande.societe_nom }}</span>
          <span v-if="commande.societe_interne"> &middot; {{ $t('societe_filiale') }}</span>
        </p>
        <a
          v-if="commande.garantie_decennale"
          class="text-decoration-none muted--text"
          :href="commande.garantie_decennale"
          target="_blank"
        >
          {{ $t('garantie-decenalle') }}           <v-icon
            color="muted"
            dense
          >
            mdi-chevron-right
          </v-icon>
        </a>
      </div>
      <div
        v-else-if="(commande.type_cmd == '1' || commande.type_cmd == '7') && !commande.is_configInternaute && !commande.is_petite_source_internaute"
        class="font-weight-regular pa-2 muted--text"
      >
        <p class="mb-2">
          {{ $t('materiel_facture') }}
          <span class="font-weight-bold"> {{ commande.societe_nom }}</span>
          <span v-if="commande.societe_interne"> &middot; {{ $t('societe_filiale') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ArticleCard from '@/components/article/Card'
import DialogCarousel from '@/components/DialogCarousel'
import Facilites from '@/components/Facilites'

import { mapGetters } from 'vuex'

export default {
  name: 'CommandeCard',
  components: {
    ArticleCard,
    DialogCarousel,
    Facilites
  },
  props: {
    commande: {
      type: Object,
      required: true
    },
    modifierConfigUrl: {
      type: String,
      default: ''
    },
    modeAffichagePrix: {
      type: Number,
      default: 1
    },
    disableInteraction: {
      type: Boolean,
      default: false
    },
    ancre: {
      type: Boolean,
      default: false
    },
    install: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL,
      petiteSourceUrl: process.env.VUE_APP_PETITE_SOURCE_URL,
      carouselLigne: false,
      show_lignes: true,
      dialogPayerAcompte: false,
      commande_versions: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    versions () {
      const versions = []

      versions.push({ label: this.$t('toutes-versions'), value: 'all', id: 'all' })

      this.commande.versions.forEach((version, index) => {
        versions.push({ label: this.$t('version', { actuelle: (index === 0 ? this.$t('actuelle') : ''), date: this.$d(new Date(version.date.replace(/-/g, '/')), 'long') }), value: version.date, id: version.date })
      })

      return versions
    }
  },
  updated () {
    this.hideScrollBarDialogOpen()
  },
  methods: {
    showLigneDetail (ligneIndex) {
      this.carouselLigne = ligneIndex
    },
    getVersion (e) {
      var versionFiltree = e.id
      var versions = document.querySelectorAll('.div-versions')

      versions.forEach((version) => {
        if (versionFiltree === 'all') {
          version.style.display = 'block'
        } else {
          if (versionFiltree === version.getAttribute('id')) {
            version.style.display = 'block'
          } else {
            version.style.display = 'none'
          }
        }
      })
    },
    hideScrollBarDialogOpen () {
      if (document.querySelector('.v-dialog__content--active .hide-scroll-while-open')) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'scroll'
      }
    }
  }
}
</script>
<style lang="scss">
  .no-interaction-ligne{
    & .commande-card:hover{
      cursor: default;
    }

    & .commande-card::before{
      background: transparent;
    }

    .v-ripple__container {
      display:none !important;
    }
  }
</style>
