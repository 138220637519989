<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="text-h4">
          {{ $t('page-non-trouvee-titre') }}
        </div>
        <div>
          {{ $t('page-non-trouvee-texte') }}
          <p v-if="$store.getters.isLoggedIn">
            {{ $t('page-non-trouvee-se-deconnecter') }}
          </p>
          <v-btn
            v-if="$store.getters.isLoggedIn"
            class="background-primary primary-hover white--text"
            @click="logout"
          >
            {{ $t('se-deconnecter') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    logout () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push({ name: 'Login' })
        })
    }
  }
}
</script>
